import { useInfiniteQuery } from '@tanstack/react-query';

import { useCurrentUser } from 'hooks/selectors/useCurrentUser';
import { useGeolocation } from 'hooks/useGeolocation';
import { mapListingResponseToListing } from 'models/mappers/ListingMapper';
import { get } from 'util/httpsClient';
import { buildQueryString } from 'util/httpUtils';

export const useGetUserListings = ({
  userId,
  enabled = true,
  searchTerm = '',
  publishedOnly,
  showOwn = true,
}: {
  userId: string;
  enabled?: boolean;
  searchTerm?: string;
  publishedOnly?: boolean;
  showOwn?: boolean;
}) => {
  const { userGeolocation } = useGeolocation();

  const { currentUser } = useCurrentUser();
  const isOwn = currentUser?.id?.uuid && userId && currentUser?.id.uuid === userId && showOwn;

  return useInfiniteQuery({
    enabled: Boolean(userId) && enabled,
    queryKey: ['userListings', { userId, searchTerm, publishedOnly }],
    queryFn: async ({ pageParam = 1 }) => {
      const queryString = buildQueryString({
        country: userGeolocation,
        page: pageParam,
        userId,
        searchTerm,
        publishedOnly,
      });
      const endpoint = isOwn ? 'own' : 'user';
      const path = `/listings/${endpoint}${queryString ? `?${queryString}` : ''}` as const;

      const response = await get({
        path,
      });

      const listings = response.listings.map(mapListingResponseToListing);

      return {
        listings,
        currentPage: response.meta.page,
        totalPages: response.meta.totalPages,
      };
    },
    getNextPageParam: lastPage => {
      if (lastPage.currentPage < lastPage.totalPages) {
        return lastPage.currentPage + 1;
      }
      return undefined;
    },
  });
};
