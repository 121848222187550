import * as Accordion from '@radix-ui/react-accordion';

import css from './InfoTabsSection.module.css';
import { IconChevronDown } from 'assets/icons';
import { Listing } from 'models/ListingModels';

const InfoTabsSection = props => {
  const { intl } = props;
  const listing: Listing = props.listing;

  const tabs = [
    {
      key: 'productInfo',
      label: 'InfoTabsSection.tabProductInfo',
      content: <p className={css.infoSmall}>{listing.description || ''}</p>,
    },

    {
      key: 'shippingReturns',
      label: 'InfoTabsSection.tabShippingAndReturns',
      content: (
        <p className={css.infoSmall}>
          When you place an order, the seller has 7 days to accept and 7 business days to ship it.
          This may be extended if there are delays or unavailability. Shipping costs are calculated
          at checkout. Taxes and duties may apply based on the shipment's origin and destination,
          and these are the buyer's responsibility.
          <br />
          <br />
          All sales on NOLD are final unless the item is misrepresented. In such cases, you have 3
          days to dispute. This does not apply to sample sale items and items sold by registered
          companies, which can be returned within 14 days.
        </p>
      ),
    },
    {
      key: 'payment',
      label: 'InfoTabsSection.tabPayment',
      content: (
        <p className={css.infoSmall}>
          All payments are handled by Stripe to ensure maximum security. Once you've completed your
          purchase, the value of the item and shipping will be held in a designated Stripe account
          for seven days. If the seller fails to ship the item within this period, you'll receive a
          full refund.
          <br />
          <br />
          Additionally, to safeguard your purchase, a buyer protection fee of GBP 4.99 is applied at
          checkout. This fee ensures that if the item is misrepresented, you can return it at the
          expense of the seller.
        </p>
      ),
    },
    {
      key: 'authenticity',
      label: 'InfoTabsSection.tabAuthenticity',
      content: (
        <p className={css.infoSmall}>
          You have the option to request product verification for GBP 15. Upon your request, we'll
          conduct authentication and provide you with the results via email. This allows you to make
          an informed decision before proceeding with your purchase.
        </p>
      ),
    },
  ];

  return (
    <Accordion.Root type="single" collapsible className={css.accordionRoot}>
      {tabs.map(tabData => (
        <Accordion.Item value={tabData.label} key={tabData.key}>
          <Accordion.Header>
            <Accordion.Trigger className={css.accordionItem__header}>
              <span>{intl.formatMessage({ id: tabData.label })}</span>
              <IconChevronDown />
            </Accordion.Trigger>
          </Accordion.Header>
          <Accordion.Content className={css.accordionItem__content}>
            {tabData.content}
            <div style={{ height: 16 }} />
          </Accordion.Content>
        </Accordion.Item>
      ))}
    </Accordion.Root>
  );
};

export default InfoTabsSection;
