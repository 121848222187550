import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { useGeolocation } from 'hooks/useGeolocation';
import { Listing } from 'models/ListingModels';
import { mapListingResponseToListing } from 'models/mappers/ListingMapper';
import { get } from 'util/httpsClient';
import { buildQueryString } from 'util/httpUtils';

type GetListingOptions = {
  isOwn?: boolean;
  country?: string;
};

export const useGetListing = (
  listingId: string,
  options: GetListingOptions = { isOwn: false },
  enabled: boolean = true
) => {
  const { userGeolocation } = useGeolocation();
  const queryOptions = { ...options, country: userGeolocation };

  return useQuery<Listing, Error>({
    enabled: Boolean(listingId) && enabled,
    queryKey: getListingQueryKey(listingId, queryOptions),
    queryFn: () => getListingQueryFn(listingId, queryOptions),
    meta: {
      errorMessage: 'Failed to fetch listing',
    },
  });
};

export const getListingQueryKey = (listingId: string, options: GetListingOptions) =>
  ['listing', listingId, options] as const;

export const getListingQueryFn = async (
  listingId: any,
  options: GetListingOptions
): Promise<Listing> => {
  const queryString = buildQueryString({
    ...options,
  });

  const id = listingId.uuid ? listingId.uuid : listingId;

  const path = `/listings/${id}${queryString ? `?${queryString}` : ''}` as const;

  const response = await get({ path });
  return mapListingResponseToListing(response);
};
