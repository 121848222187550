import classNames from 'classnames';

import { FormattedMessage } from '../../util/reactIntl';
import NoImageIcon from './NoImageIcon';
import css from './ResponsiveImage.module.css';
import { ImageLazy } from 'components';
import { Image } from 'util/images';

interface ResponsiveImageV2Props {
  className?: string | null;
  rootClassName?: string | null;
  alt: string;
  image?: Image | null;
  noImageMessage?: string | null;
  loading?: 'eager' | 'lazy' | undefined;
  objectFit?: 'cover' | 'contain' | 'fill' | 'none' | 'scale-down';
}

const ResponsiveImageV2: React.FC<ResponsiveImageV2Props> = props => {
  const {
    loading,
    className,
    rootClassName,
    alt,
    noImageMessage,
    image,
    objectFit = 'cover',
    ...rest
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  if (image && image.variants) {
    const { srcset, onError } = image;
    const blurSrc = image.variants.blur?.url;

    const imgProps = {
      className: classes,
      srcSet: srcset,
      onError,
      loading,
      ...rest,
    };

    return <ImageLazy objectFit={objectFit} blurSrc={blurSrc} alt={alt} {...imgProps} />;
  } else {
    const noImageClasses = classNames(rootClassName || css.root, css.noImageContainer, className);
    const noImageMessageText = noImageMessage || <FormattedMessage id="ResponsiveImage.noImage" />;
    return (
      <div className={noImageClasses}>
        <div className={css.noImageWrapper}>
          <NoImageIcon className={css.noImageIcon} />
          <div className={css.noImageText}>{noImageMessageText}</div>
        </div>
      </div>
    );
  }
};

export default ResponsiveImageV2;
