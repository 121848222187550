import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import css from './ImageLazy.module.css';

type P = React.ImgHTMLAttributes<HTMLImageElement> & {
  blurSrc: string | undefined;
  // We use objectFit to make sure that the blurry loaded image background
  // matches the final image background size.
  objectFit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down';
};

const ImageLazy = React.forwardRef<HTMLImageElement, P>((props, ref) => {
  const {
    src,
    srcSet,
    blurSrc,
    objectFit = 'cover',
    className,
    style,
    alt,
    onError,
    ...rest
  } = props;
  const [loaded, setLoaded] = useState(!blurSrc);
  const backgroundSize = objectFit === 'contain' || objectFit === 'cover' ? objectFit : 'auto';

  useEffect(() => {
    let img: HTMLImageElement | null = null;
    let currentSrcImg: HTMLImageElement | null = null;

    const cleanup = () => {
      if (img) {
        img.srcset = '';
        img.onload = null;
      }
      if (currentSrcImg) {
        currentSrcImg.src = '';
        currentSrcImg.onload = null;
      }
    };

    if (srcSet) {
      img = new Image();
      img.srcset = srcSet;
      if (img.complete) {
        setLoaded(true);
      } else {
        img.onload = () => {
          currentSrcImg = new Image();
          currentSrcImg.src = img!.currentSrc;
          currentSrcImg.onload = () => setLoaded(true);
        };
      }
    } else if (src) {
      img = new Image();
      img.src = src;
      if (img.complete) {
        setLoaded(true);
      } else {
        img.onload = () => setLoaded(true);
      }
    }

    return cleanup;
  }, [src, srcSet]);

  return (
    <img
      alt={alt}
      src={src}
      style={{
        backgroundImage: !loaded ? `url(${blurSrc})` : 'none',
        backgroundSize,
        objectFit,
        ...style,
      }}
      srcSet={srcSet}
      ref={ref}
      className={classNames(css.root, className, { [css.loaded]: loaded })}
      onError={onError}
      {...rest}
    />
  );
});

export default ImageLazy;
