/**
 * Usage without sizes:
 *   <ResponsiveImage
 *     alt="ListingX"
 *     image={imageDataFromSDK}
 *     variants={['landscape-crop', 'landscape-crop2x']}
 *   />
 *   // produces:
 *   <img
 *     alt="ListingX"
 *     src="url/to/landscape-crop.jpg"
 *     srcSet="url/to/landscape-crop.jpg 400w, url/to/landscape-crop2x.jpg 800w" />
 *
 * Usage with sizes:
 *   <ResponsiveImage
 *     alt="ListingX"
 *     image={imageDataFromSDK}
 *     variants={['landscape-crop', 'landscape-crop2x']}
 *     sizes="(max-width: 600px) 100vw, 50vw"
 *   />
 *   // produces:
 *   <img
 *     alt="ListingX"
 *     src="url/to/landscape-crop.jpg"
 *     srcSet="url/to/landscape-crop.jpg 400w, url/to/landscape-crop2x.jpg 800w"
 *     sizes="(max-width: 600px) 100vw, 50vw" />
 *
 *   // This means that below 600px image will take as many pixels there are available on current
 *   // viewport width (100vw) - and above that image will only take 50% of the page width.
 *   // Browser decides which image it will fetch based on current screen size.
 *
 * NOTE: for all the possible image variant names and their respective
 * sizes, see the API documentation.
 */
import classNames from 'classnames';
import { arrayOf, oneOfType, string } from 'prop-types';

import { ImageSize, sizeToWidth } from '../../util/images/imageSize';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import NoImageIcon from './NoImageIcon';
import css from './ResponsiveImage.module.css';
import { ImageLazy } from 'components';

export const getImageUrl = (imageId, size = ImageSize.ORIGINAL) => {
  return `https://cdn.thenold.com/images/listings/${size}/${imageId}.webp`;
};

const ResponsiveImage = props => {
  const {
    loading,
    className,
    rootClassName,
    alt,
    noImageMessage,
    image,
    imageId,
    variants,
    sizes = [ImageSize.BLUR, ImageSize.SMALL, ImageSize.MEDIUM, ImageSize.LARGE, ImageSize.XLARGE],
    sizeAttributes,
    objectFit,
    ...rest
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  if ((!image && !imageId) || sizes.length === 0) {
    const noImageClasses = classNames(rootClassName || css.root, css.noImageContainer, className);
    const noImageMessageText = noImageMessage || <FormattedMessage id="ResponsiveImage.noImage" />;
    return (
      <div className={noImageClasses}>
        <div className={css.noImageWrapper}>
          <NoImageIcon className={css.noImageIcon} />
          <div className={css.noImageText}>{noImageMessageText}</div>
        </div>
      </div>
    );
  }

  if (image && image.attributes && image.attributes.variants && variants && variants.length > 0) {
    const imageVariants = image.attributes.variants;

    const srcSet = variants
      .map(variantName => {
        const variant = imageVariants[variantName];

        if (!variant) {
          // Variant not available (most like just not loaded yet)
          return null;
        }
        return `${variant.url} ${variant.width}w`;
      })
      .filter(v => v != null)
      .join(', ');

    const blurVariantSrc = imageVariants?.blur?.url;
    const src = imageVariants[variants[0]]?.url;

    const imgProps = {
      className: classes,
      srcSet,
      src,
      sizes: sizeAttributes,
      loading,
      ...rest,
    };

    return <ImageLazy objectFit={objectFit} blurSrc={blurVariantSrc} alt={alt} {...imgProps} />;
  } else if (imageId) {
    // New logic: construct URLs using imageId and sizes

    const srcSet = sizes
      .map(size => {
        const url = getImageUrl(imageId, size);
        const width = sizeToWidth[size];
        if (!url || !width) {
          return null;
        }
        return `${url} ${width}w`;
      })
      .filter(v => v != null)
      .join(', ');

    const src = getImageUrl(imageId, sizes[0]);
    const blurSrc = getImageUrl(imageId, ImageSize.BLUR);

    const imgProps = {
      className: classes,
      srcSet,
      src,
      sizes: sizeAttributes,
      loading,
      ...rest,
    };

    return <ImageLazy objectFit={objectFit} blurSrc={blurSrc} alt={alt} {...imgProps} />;
  } else {
    const noImageClasses = classNames(rootClassName || css.root, css.noImageContainer, className);
    const noImageMessageText = noImageMessage || <FormattedMessage id="ResponsiveImage.noImage" />;
    return (
      <div className={noImageClasses}>
        <div className={css.noImageWrapper}>
          <NoImageIcon className={css.noImageIcon} />
          <div className={css.noImageText}>{noImageMessageText}</div>
        </div>
      </div>
    );
  }
};

ResponsiveImage.defaultProps = {
  className: null,
  rootClassName: null,
  image: null,
  imageId: null,
  variants: null,
  sizes: null,
  noImageMessage: null,
  sizeAttributes: null,
  objectFit: 'cover',
};

ResponsiveImage.propTypes = {
  className: string,
  rootClassName: string,
  alt: string.isRequired,
  // @ts-expect-error TS(2339) FIXME: Property 'image' does not exist on type '{}'.
  image: oneOfType([propTypes.image, propTypes.imageAsset]),
  imageId: string,
  variants: arrayOf(string),
  sizes: arrayOf(string),
  sizeAttributes: string,
  noImageMessage: string,
  loading: string,
  objectFit: string,
};

export default ResponsiveImage;
