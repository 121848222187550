import classNames from 'classnames';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { IconSpinner, ListingCard } from '../../../components';
import css from './ProfileListings.module.css';
import { Listing } from 'models/ListingModels';

export const ProfileListings: React.FC<{
  listings: Listing[];
  className?: string;
  hasMore: boolean;
  loadMore: () => void;
}> = ({ listings, className, hasMore, loadMore }) => {
  if (!listings || listings.length === 0) {
    return null;
  }

  return (
    <div className={classNames(css.listingsContainer, className)}>
      <InfiniteScroll
        dataLength={listings.length}
        next={loadMore}
        hasMore={hasMore}
        loader={
          <div className={css.spinnerWrapper}>
            <IconSpinner />
          </div>
        }
        scrollThreshold={0.8}
      >
        <ul className={css.listings}>
          {listings.map(l => (
            <li className={css.listing} key={l.id}>
              <ListingCard listing={l} addToFavoritesShown manageListingsShown />
            </li>
          ))}
        </ul>
      </InfiniteScroll>
    </div>
  );
};
