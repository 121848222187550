import * as Dialog from '@radix-ui/react-dialog';
import classNames from 'classnames';
import React, { useRef } from 'react';

import AddToFavoritesButton from '../../containers/ListingPage/AddToFavoritesButton/AddToFavoritestButton';
import css from './SingleProductGallery.module.css';
import { IconChevronLeft, IconChevronRight, IconClose } from 'assets/icons';
import ImageLazy from 'components/ImageLazy/ImageLazy';
import { Listing } from 'models/ListingModels';

type SingleProductGalleryProps = {
  currentListing: Listing;
  className?: string | null;
  rootClassName?: string | null;
  onOpenModal?: () => void;
  photosFilter?: 'seller' | 'original';
};

export const SingleProductGallery: React.FC<SingleProductGalleryProps> = props => {
  const { currentListing, onOpenModal, photosFilter } = props;

  const sliderRef = useRef(null);
  const sliderImageRef = useRef(null);

  const scrollToNextSlide = () => {
    if (sliderRef.current) {
      // @ts-expect-error TS(2339) FIXME: Property 'scrollBy' does not exist on type 'never'... Remove this comment to see the full error message
      sliderRef.current.scrollBy({
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        left: sliderImageRef.current.offsetWidth,
        behavior: 'smooth',
      });
    }
  };

  const scrollToPrevSlide = () => {
    if (sliderRef.current) {
      // @ts-expect-error TS(2339) FIXME: Property 'scrollBy' does not exist on type 'never'... Remove this comment to see the full error message
      sliderRef.current.scrollBy({
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        left: -sliderImageRef.current.offsetWidth,
        behavior: 'smooth',
      });
    }
  };

  let images = currentListing.images;
  if (photosFilter === 'seller') {
    images = images.filter(image => !currentListing.originalPhotoIds?.includes(image.id));
  } else if (photosFilter === 'original') {
    images = images.filter(image => currentListing.originalPhotoIds?.includes(image.id));
  }

  return (
    <>
      <div className={css.root}>
        <div
          className={classNames(css.imagesWrapper, {
            [css.imagesWrapperSingle]: images.length === 1,
          })}
          ref={sliderRef}
          onClick={onOpenModal}
        >
          {images?.map(image => (
            <ImageWrapper
              key={image.id}
              ref={sliderImageRef}
              image={image}
              onOpenModal={onOpenModal}
            />
          ))}
        </div>
        <AddToFavoritesButton listing={currentListing} />
        {images.length > 1 ? (
          <>
            <button className={css.prevButton} onClick={scrollToPrevSlide}>
              <IconChevronLeft />
            </button>
            <button className={css.nextButton} onClick={scrollToNextSlide}>
              <IconChevronRight />
            </button>
          </>
        ) : null}
      </div>
    </>
  );
};

const ImageWrapper = React.forwardRef<HTMLImageElement, any>((props, ref) => {
  const { image, onOpenModal } = props;

  const { srcset, onError } = image;
  const blurSrc = image.variants.blur?.url;

  return (
    <ImageLazy
      onClick={onOpenModal}
      ref={ref}
      srcSet={srcset}
      loading="lazy"
      blurSrc={blurSrc}
      onError={onError}
    />
  );
});

export const SingleProductGalleryModal: React.FC<
  SingleProductGalleryProps & Pick<Dialog.DialogProps, 'open' | 'onOpenChange'>
> = props => {
  const { open, onOpenChange, ...rest } = props;
  const photosFilter = props.photosFilter;

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay className={css.dialog__overlay} onClick={() => onOpenChange?.(false)} />
        <Dialog.Content className={css.dialog__content}>
          <div style={{ position: 'absolute', inset: '0' }} onClick={() => onOpenChange?.(false)} />
          <IconClose className={css.dialog__close} onClick={() => onOpenChange?.(false)} />
          {photosFilter &&
            (photosFilter === 'seller' ? (
              <h2 className={css.dialog__title}>Seller photos</h2>
            ) : (
              <h2 className={css.dialog__title}>Original photos</h2>
            ))}
          <SingleProductGallery {...rest} />
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default SingleProductGallery;
