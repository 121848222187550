import { useInfiniteQuery } from '@tanstack/react-query';

import { GetListingsRequest } from 'containers/SearchPage/SearchPage.hooks';
import { mapListingResponseToListing } from 'models/mappers/ListingMapper';
import { get } from 'util/httpsClient';
import { buildQueryString } from 'util/httpUtils';

export const fetchListingsByIds = async (ids: string[], request: GetListingsRequest) => {
  const queryString = buildQueryString({
    ids: ids.join(','),
    ...request,
  });

  const path = `/listings/ids${queryString ? `?${queryString}` : ''}` as const;

  const response = await get({
    path,
  });

  const listings = response.listings.map(mapListingResponseToListing);

  return {
    listings,
    currentPage: response.meta.page,
    totalPages: response.meta.totalPages,
  };
};

export const useGetListingsByIds = (
  ids: string[],
  request: GetListingsRequest,
  { enabled }: { enabled?: boolean } = { enabled: true }
) => {
  return useInfiniteQuery({
    queryKey: ['listingsByIds', ids],
    queryFn: () => fetchListingsByIds(ids, request),
    getNextPageParam: lastPage => {
      if (lastPage.currentPage < lastPage.totalPages) {
        return lastPage.currentPage + 1;
      }
      return undefined;
    },
    meta: {
      errorMessage: 'Failed to fetch listings',
    },
    enabled,
  });
};

export const getListingsByIdsQueryKey = (ids: string[]) => ['listingsByIds', ids] as const;
