import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ListingAction } from 'models/enums/ListingEnums';
import { patch } from 'util/httpsClient';

const useListingAction = (actionType: ListingAction) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (listingId: string) =>
      patch({ path: `/listings/${listingId}`, body: { actionType } }),
    onSuccess: () => {
      queryClient.invalidateQueries(['userListings']);
    },
    meta: { name: `listingAction_${actionType}` },
  });
};

export const useCloseListing = () => useListingAction(ListingAction.CLOSE);
export const useActivateListing = () => useListingAction(ListingAction.OPEN);
export const useMarkListingAsSold = () => useListingAction(ListingAction.SOLD);
export const useRestockListing = () => useListingAction(ListingAction.RESTOCK);
export const useDeleteListing = () => useListingAction(ListingAction.DELETE);
