import { ImageSize } from './images/imageSize';
import { createImageVariantConfig } from './sdkLoader';

const srcsetScreenSizes = {
  small: '220w',
  medium: '320w',
  large: '800w',
  xlarge: '1600w',
};

function generateSrcset(variants: Record<string, { url: string }>, sizes = srcsetScreenSizes) {
  return Object.entries<{ url: string }>(variants)
    .filter(([key]) => sizes[key])
    .map(([key, { url }]) => `${url} ${sizes[key] || ''}`.trim())
    .join(', ');
}

const typeVariants = {
  listings: [
    ImageSize.ORIGINAL,
    ImageSize.BLUR,
    ImageSize.SMALL,
    ImageSize.MEDIUM,
    ImageSize.LARGE,
    ImageSize.XLARGE,
  ],
  profiles: [
    ImageSize.ORIGINAL,
    ImageSize.BLUR,
    ImageSize.SMALL,
    ImageSize.MEDIUM,
    ImageSize.LARGE,
  ],
  looking_for: [
    ImageSize.ORIGINAL,
    ImageSize.BLUR,
    ImageSize.SMALL,
    ImageSize.MEDIUM,
    ImageSize.LARGE,
  ],
} as const;

export function originalToVariants(url: string) {
  if (!url) {
    return undefined;
  }

  const pathComponents = url.split('/');
  const imageVariant = pathComponents[pathComponents.length - 2];

  if (imageVariant !== 'original') {
    throw new Error('Image is not an original image');
  }

  const type = pathComponents[pathComponents.length - 3] as keyof typeof typeVariants;
  const variants = typeVariants[type];

  if (!variants) {
    throw new Error('No variants found for image type');
  }

  function onError(e: React.SyntheticEvent<HTMLImageElement>) {
    if (e.currentTarget.src !== url) {
      e.currentTarget.setAttribute('src', url);
      e.currentTarget.setAttribute('srcset', url);
    }
  }

  const response: Record<string, { url: string; onError: typeof onError }> = Object.fromEntries(
    variants.map(variant => [
      variant,
      {
        url: url.replace('original', variant),
        onError,
      },
    ])
  );

  return { variants: response, srcset: generateSrcset(response), onError };
}
export type ImageVariants = ReturnType<typeof originalToVariants>;

export function toImageObject(id: string) {
  return {
    id,
    ...originalToVariants(getImageUrl(id, ImageSize.ORIGINAL)),
  };
}

export type Image = ReturnType<typeof toImageObject>;

export const getImageUrl = (imageId, size) => {
  return `https://cdn.thenold.com/images/listings/${size}/${imageId}.webp`;
};

export const getImageKey = (imageId, size, removeBackground = false) => {
  return `images/listings/${size}/${imageId}${removeBackground ? '_removebg.webp' : '.webp'}`;
};

export function getLargestVariant(variants) {
  const variantKeys = Object.keys(variants);

  const largestVariant = variantKeys.reduce((acc, key) => {
    const variant = variants[key];
    return variant.width * variant.height > acc.width * acc.height ? variant : acc;
  }, variants[variantKeys[0]]);

  return largestVariant;
}

export const BLUR_VARIANT = 'variants.blur';
export function createBlurVariant(aspectRatio: number) {
  return createImageVariantConfig('blur', 50, aspectRatio);
}
