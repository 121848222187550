export enum ListingState {
  draft = 'draft',
  pendingApproval = 'pending-approval',
  published = 'published',
  closed = 'closed',
  sold = 'sold',
  manuallySold = 'manually-sold',
  deleted = 'deleted',
}

export enum ConditionType {
  neverWornWithTag = 'never-worn-with-tag',
  neverWornWithoutTag = 'never-worn-without-tag',
  excellent = 'excellent',
  goodWithNegligibleDefects = 'good-with-negligible-defects',
  goodWithDefects = 'good-with-defects',
  sampleSale = 'sample-sale',
}

export enum ShippingRegion {
  UK = 'UK',
  EU = 'EU',
}

export enum ListingAction {
  CLOSE = 'close',
  OPEN = 'open',
  SOLD = 'sold',
  RESTOCK = 'restock',
  DELETE = 'delete',
}
