import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuPortal,
  DropdownMenuTrigger,
} from '@radix-ui/react-dropdown-menu';
import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

import { AspectRatioWrapper, NamedLink, PrimaryButton } from '../../components';
import { useConfiguration } from '../../context/configurationContext';
import { formatMoney, newMoney } from '../../util/currency';
import { useIntl } from '../../util/reactIntl';
import { richText } from '../../util/richText';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  stringify,
} from '../../util/urlHelpers';
import css from './ListingCard.module.css';
import { IconEllipsisHorizontal } from 'assets/icons';
import { Badge } from 'components/_chadcn/Badge';
import ResponsiveImageV2 from 'components/ResponsiveImage/ResponsiveImageV2';
import AddToFavoritesButton from 'containers/ListingPage/AddToFavoritesButton/AddToFavoritestButton';
import { ListingManagementButtons } from 'containers/ProfilePage/ListingManagementButtons/ListingManagementButtons';
import { FILTERS_DEFAULTS, FiltersQueryParams } from 'containers/SearchPage/SearchPage.filters';
import { useHandleListingAction } from 'hooks/api/listings/useHandleListingAction';
import { useCurrentUser } from 'hooks/selectors/useCurrentUser';
import { useGeolocation } from 'hooks/useGeolocation';
import { ListingState } from 'models/enums/ListingEnums';
import { Listing } from 'models/ListingModels';
import { createListingSlug } from 'util/createListingSlug';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, configCurrency, intl) => {
  if (!price?.amount || !price?.currency) {
    return {};
  }

  const formattedPrice = formatMoney(intl, price);

  // Always return the formatted price, even if currencies don't match
  return {
    formattedPrice,
    priceTitle: `${formattedPrice} (${price.currency})`,
    isConfigCurrency: price.currency === configCurrency,
  };
};

interface ListingCardProps {
  className?: string;
  rootClassName?: string;
  listing: Listing;
  showTitle?: boolean;
  showPriceInfo?: boolean;
  renderSizes?: string;
  setActiveListing?: (listingId) => void;

  addToFavoritesShown?: boolean;
  manageListingsShown?: boolean;
  addToFavoritesClass?: string;
  appendSizeToTitle?: boolean;
  onClick?: () => void;
}

export const ListingCard: React.FC<ListingCardProps> = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    listing,
    setActiveListing,
    showTitle = true,
    showPriceInfo = true,
    addToFavoritesShown,
    manageListingsShown,
    addToFavoritesClass,
    appendSizeToTitle,
    onClick,
  } = props;

  const intl = useIntl();
  const classes = classNames(rootClassName || css.root, className);
  const id = listing.id;
  const { title = '', brandId } = listing;
  const brandName = listing?.brand?.name;

  const slug = createListingSlug(listing);
  const firstImage = listing.images && listing.images.length > 0 ? listing.images[0] : null;
  const price = newMoney(listing.price, listing.currency);
  const originalPrice = newMoney(listing.priceWhenBought, listing.priceWhenBoughtCurrency);
  const listingState = listing.state;
  const noStock = listing.stockQuantity === 0;

  const isPublished = listingState === ListingState.published;
  const isDraft = listingState === ListingState.draft;
  const isPendingApproval = listingState === ListingState.pendingApproval;
  const isClosed = listingState === ListingState.closed;
  const isDeleted = listingState === ListingState.deleted;
  const isManuallySold = listingState === ListingState.manuallySold;
  const isSold = listingState === ListingState.sold;
  const hasStatus =
    isClosed || isPendingApproval || isDeleted || isDraft || isManuallySold || noStock;

  const { currentUser } = useCurrentUser();
  const isOwnListing = currentUser?.id?.uuid === listing?.authorId;
  const isActive = isPendingApproval || isPublished || isSold;
  const isListingActionAllowed = isOwnListing && manageListingsShown;

  const size = listing.size?.toUpperCase();
  const shouldAppendSize = !title.endsWith(`(${size})`);
  const titleWithSize =
    appendSizeToTitle && size && shouldAppendSize ? `${title} (${size})` : title;

  const { aspectWidth = 3, aspectHeight = 4 } = config.layout.listingImage;

  const { formattedPrice, priceTitle } = priceData(
    newMoney(price.amount, price.currency),
    config.currency,
    intl
  );
  const { formattedPrice: formattedOriginalPrice, priceTitle: originalPriceTitle } = priceData(
    originalPrice,
    config.currency,
    intl
  );

  const { handleActivateListing, isActivateLoading, isRestockLoading } = useHandleListingAction();

  const isActivateInProgress =
    listingState === ListingState.manuallySold ? isRestockLoading : isActivateLoading;

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(listing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  const { userGeolocation } = useGeolocation();
  const showOnlyShippedToGBBadge =
    isActive &&
    userGeolocation !== 'GB' &&
    Array.isArray(listing.shipsTo) &&
    !listing.shipsTo.some(destination => destination.toLowerCase() === 'eu');

  const showOnlyShippedToEUBadge =
    isActive &&
    userGeolocation === 'GB' &&
    Array.isArray(listing.shipsTo) &&
    !listing.shipsTo.some(destination => destination.toLowerCase() === 'gb');

  const WrapperComponent = onClick
    ? ({ children }: PropsWithChildren<{}>) => {
        return (
          <div onClick={onClick} className={classes}>
            {children}
          </div>
        );
      }
    : ({ children }: PropsWithChildren<{}>) => (
        <NamedLink
          className={classes}
          name={isDraft || isPendingApproval ? 'ListingPageVariant' : 'ListingPage'}
          params={{
            id,
            slug,
            ...(isDraft
              ? { variant: LISTING_PAGE_DRAFT_VARIANT }
              : isPendingApproval
              ? { variant: LISTING_PAGE_PENDING_APPROVAL_VARIANT }
              : {}),
          }}
        >
          {children}
        </NamedLink>
      );

  return (
    <WrapperComponent>
      <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        width={aspectWidth}
        height={aspectHeight}
        {...setActivePropsMaybe}
      >
        {showOnlyShippedToGBBadge && (
          <Badge
            variant="accent"
            className="top-2 left-2 font-medium px-1 py-.25 text-0000 absolute uppercase text-center z-10"
          >
            GB Shipping Only
          </Badge>
        )}
        {showOnlyShippedToEUBadge && (
          <Badge
            variant="accent"
            className="top-2 left-2 font-medium px-1 py-.25 text-0000 absolute uppercase text-center z-10"
          >
            EU Shipping Only
          </Badge>
        )}
        <ResponsiveImageV2
          rootClassName={css.rootForImage}
          alt={title}
          image={firstImage}
          loading="lazy"
          objectFit="contain"
        />

        {addToFavoritesShown && !isOwnListing && isActive && (
          <AddToFavoritesButton listing={listing} className={addToFavoritesClass} />
        )}
        {hasStatus && (
          <div className={css.overlayInfo}>
            <div className={css.overlayInfo__container}>
              <span>
                {noStock ? 'Sold' : isDraft ? 'Draft' : isClosed ? 'Closed' : 'Pending Approval'}
              </span>
              {isListingActionAllowed && isDraft && (
                <>
                  <NamedLink
                    name="EditListingPage"
                    params={{
                      id,
                      slug,
                      tab: 'photos',
                      type: 'edit',
                    }}
                  >
                    <PrimaryButton className={css.transparentButton}>EDIT</PrimaryButton>
                  </NamedLink>
                  <span className={css.buttonInfoText}>Finish and submit for review</span>
                </>
              )}
              {isListingActionAllowed && (isClosed || isManuallySold) && (
                <>
                  <PrimaryButton
                    className={css.transparentButton}
                    onClick={async e => {
                      e.preventDefault();
                      e.stopPropagation();
                      await handleActivateListing(id, isManuallySold);
                    }}
                    disabled={isActivateInProgress}
                    inProgress={isActivateInProgress}
                  >
                    ACTIVATE
                  </PrimaryButton>
                  <span className={css.buttonInfoText}>Make available for sale</span>
                </>
              )}{' '}
            </div>
          </div>
        )}
        {isListingActionAllowed && (!noStock || isManuallySold) && (
          <DropdownMenu>
            <DropdownMenuTrigger className={css.manageMenuTrigger}>
              <IconEllipsisHorizontal />
            </DropdownMenuTrigger>
            <DropdownMenuPortal>
              <DropdownMenuContent sideOffset={16}>
                <ListingManagementButtons
                  isActive={isActive}
                  isDraft={isDraft}
                  listing={listing}
                  isClosed={isClosed}
                  isManuallySold={isManuallySold}
                />
              </DropdownMenuContent>
            </DropdownMenuPortal>
          </DropdownMenu>
        )}
      </AspectRatioWrapper>
      <div className={css.info}>
        {brandName && (
          <NamedLink
            name="SearchPage"
            className={!showTitle && !showPriceInfo ? 'self-center' : ''}
            to={{
              search: stringify({
                ...FILTERS_DEFAULTS,
                brandIds: [brandId],
              } as FiltersQueryParams),
            }}
          >
            <h2 className={css.brandName}>{brandName}</h2>
          </NamedLink>
        )}
        {showTitle && (
          <div className={css.title}>
            {richText(titleWithSize, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
        )}
        {showPriceInfo && (
          <div className={css.price}>
            <div className={css.price__value} title={priceTitle}>
              {formattedPrice}
            </div>
            <div className={css.price__originalValue} title={originalPriceTitle}>
              {formattedOriginalPrice}
            </div>
          </div>
        )}
      </div>
    </WrapperComponent>
  );
};

export default ListingCard;
