import { ConditionType, ConditionTypeLabels } from '../../../../config/configListing';
import InfoTabsSection from '../../../../containers/ListingPage/InfoTabsSection/InfoTabsSection';
import { userDisplayNameAsStringV2 } from '../../../../util/data';
import { FormattedMessage } from '../../../../util/reactIntl';
import NamedLink from '../../../NamedLink/NamedLink';
import css from './ProductInfoSection.module.css';
import { Listing } from 'models/ListingModels';

const ProductInfoSection = props => {
  const { intl, marketplaceCurrency, author } = props;
  const listing: Listing = props.listing;

  const authorDisplayName = userDisplayNameAsStringV2(author, '');

  const { conditionDetails, condition, size, sizeStandard, shippingAddress } = listing;
  const sizeLabel = [sizeStandard, size]
    .filter(s => s)
    .join(' ')
    .toUpperCase();

  let shippingFrom;
  const listingCondition = listing.condition;

  if (listingCondition === ConditionType.SampleSale) {
    shippingFrom = 'European Union or UK';
  } else {
    shippingFrom = [shippingAddress?.city, shippingAddress?.country].filter(s => s).join(', ');
  }

  const conditionLabel = ConditionTypeLabels[condition] || condition;

  if (!listing?.id) {
    return null;
  }
  return (
    <div className={css.root}>
      <div className={css.divider} />
      <div className={css.container}>
        {sizeLabel && (
          <>
            <div className={css.label}>
              <FormattedMessage id="ProductInfoSection.sizeLabel" />
            </div>
            <span className={css.value}>{sizeLabel}</span>
          </>
        )}
        <div className={css.label}>
          <FormattedMessage id="ProductInfoSection.conditionLabel" />
        </div>
        <span className={css.value}>{conditionLabel}</span>
        <div className={css.label}>
          <FormattedMessage id="ProductInfoSection.shippedFromLabel" />
        </div>
        <span className={css.value}>{shippingFrom}</span>
        <div className={css.label}>
          <FormattedMessage id="ProductInfoSection.soldByLabel" />
        </div>
        <div className={css.value}>
          <NamedLink
            name="ProfilePage"
            params={{ id: author.id }}
            style={{ textDecoration: 'underline' }}
          >
            {authorDisplayName}
          </NamedLink>
          <span style={{ margin: '0 var(--n-size-1)' }}>|</span>
          <span style={{ fontWeight: author?.metadata?.averageRating ? 500 : 400 }}>
            {author?.metadata?.averageRating
              ? `${author.metadata.averageRating} rating`
              : 'No rating yet'}
          </span>
        </div>
        {conditionDetails && (
          <>
            <div className={css.label}>
              <FormattedMessage id="ProductInfoSection.commentLabel" />
            </div>
            <span className={css.value}>{conditionDetails}</span>
          </>
        )}
      </div>
      <div className={css.divider} style={{ marginBottom: 24 }} />
      <InfoTabsSection listing={listing} intl={intl} marketplaceCurrency={marketplaceCurrency} />
    </div>
  );
};

export default ProductInfoSection;
