import { useParams } from 'react-router-dom';

import { CheckboxInputs } from '../CheckoutCheckboxInputs/CheckoutCheckboxInputs';
import { DiscountCodeInput } from '../CheckoutDiscountCodeInput/CheckoutDiscountCodeInput';
import css from './CheckoutSummary.module.css';
import IconSpinner from 'components/IconSpinner/IconSpinner';
import OrderBreakdown from 'components/OrderBreakdown/OrderBreakdown';
import OrderSummary from 'components/OrderSummary/OrderSummary';
import { useGetListing } from 'hooks/api/listings/useGetListing';

const CheckoutSummary = ({
  orderData,
  setOrderData,
  children,
  onApply,
  onRemove,
  isLoadingLineItems,
  lineItemsResponse,
  discountCodeState,
  setDiscountCodeState,
  isGuest,
}) => {
  const { id: listingId } = useParams<{ id: string }>();
  const { data: listing } = useGetListing(listingId);

  if (!listing) {
    return null;
  }

  const lineItems = lineItemsResponse?.lineItems;

  const total = lineItemsResponse?.payinTotal;

  return (
    <div className={css.root}>
      {listing.id && <OrderSummary currentListing={listing} />}
      <DiscountCodeInput
        onApply={onApply}
        onRemove={onRemove}
        discountCodeState={discountCodeState}
        setDiscountCodeState={setDiscountCodeState}
      />
      {discountCodeState.error && <p style={{ color: 'red' }}>{discountCodeState.error}</p>}

      {!isGuest && <CheckboxInputs orderData={orderData} setOrderData={setOrderData} />}
      {isLoadingLineItems ? (
        <div className={css.loadingSpinner}>
          <IconSpinner />
        </div>
      ) : (
        lineItems && lineItems.length > 0 && <OrderBreakdown lineItems={lineItems} total={total} />
      )}
      <div className={css.chargeDisclaimer}>
        You will only be charged if your request is accepted within seven days by the seller of the
        item.
      </div>
      {children}
    </div>
  );
};

export default CheckoutSummary;
