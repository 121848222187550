import { v4 as uuidv4 } from 'uuid';
import { ImageVariants } from './images';

const MAX_WIDTH_PX = 3000;
const MAX_HEIGHT_PX = 3000;

export interface ProcessedImage {
  processedFile: File;
  imageId: string;
  originalFile: File;
  variants?: ImageVariants;
}

export async function convertToWebpAndCompress(file: File): Promise<File> {
  const { readAndCompressImage } = await import('browser-image-resizer');

  const processedBlob = await readAndCompressImage(file, {
    quality: 0.9,
    maxWidth: MAX_WIDTH_PX,
    maxHeight: MAX_HEIGHT_PX,
    debug: false,
    mimeType: 'image/webp',
  });

  return new File([processedBlob], file.name.replace(/\.[^/.]+$/, '.webp'), {
    type: 'image/webp',
  });
}

export const processImage = async (file: File): Promise<ProcessedImage> => {
  const processedFile = await convertToWebpAndCompress(file);
  const uuid = uuidv4();

  return {
    processedFile: new File([processedFile], `${uuid}.webp`, { type: 'image/webp' }),
    imageId: uuid,
    originalFile: file,
  };
};

export const uploadToS3WithPresignedUrl = async (
  presignedUrl: string,
  file: File
): Promise<void> => {
  await fetch(presignedUrl, {
    method: 'PUT',
    body: file,
    headers: {
      'Content-Type': file.type,
    },
  });
};
