/**
 * Builds a query string from an object of parameters.
 *
 * @param params - An object containing the query parameters.
 * @returns A string representing the query string (without the leading '?').
 */
export function buildQueryString(params: Record<string, any>): string {
  const queryParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      if (Array.isArray(value)) {
        value.forEach(v => {
          if (v !== undefined && v !== null) {
            queryParams.append(key, v.toString());
          }
        });
      } else if (typeof value === 'object') {
        queryParams.append(key, JSON.stringify(value));
      } else {
        queryParams.append(key, value.toString());
      }
    }
  });

  return queryParams.toString();
}

/**
 * Builds a full URL with query string from a base URL and an object of parameters.
 *
 * @param baseUrl - The base URL to append the query string to.
 * @param params - An object containing the query parameters.
 * @returns A string representing the full URL with the query string.
 */
export function buildUrlWithQueryString(baseUrl: string, params: Record<string, any>): string {
  const queryString = buildQueryString(params);
  return queryString ? `${baseUrl}?${queryString}` : baseUrl;
}

/**
 * Uploads a file to a given URL using a PUT request.
 *
 * @param url - The URL to upload the file to.
 * @param file - The file to upload.
 * @returns A promise that resolves to the URL of the uploaded file.
 */

export async function uploadFile(url: string, file: File): Promise<string> {
  try {
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': file.type,
      },
      body: file,
    });

    if (!response.ok) {
      throw new Error(`Upload failed with status ${response.status}`);
    }

    // Return the URL without query parameters
    return url.split('?')[0];
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
}

/**
 * Omits undefined values from an object.
 *
 * @param obj - The object to omit undefined values from.
 * @returns A new object with undefined values removed.
 */
export const omitUndefined = (obj: Record<string, any>) => {
  return Object.fromEntries(Object.entries(obj).filter(([_, value]) => value != null));
};
