import { useInfiniteQuery, UseInfiniteQueryResult } from '@tanstack/react-query';

import { getAllTransitionsForEveryProcess } from '../../../transactions/transaction';
import { denormalisedResponseEntities } from '../../../util/data';
import { useSdk } from '../../../util/sdkContext';
import { useCurrentUser } from '../../selectors/useCurrentUser';
import { fetchListingsByIds } from '../listings/useGetListingsByIds';
import { Listing } from 'models/ListingModels';
import { mapSharetribeListingToListing } from 'models/mappers/ListingMapper';

export enum TransactionFilter {
  ALL = 'all',
  SALES = 'sales',
  ORDERS = 'orders',
}

export interface Transaction {
  id: any;
  type: string;
  attributes: any;
  provider: any;
  customer: any;
  listing: Listing;
  userRole: string;
  otherUser: any;
  isCurrentUserProvider: any;
}

export const useGetTransactions = ({
  filter: transactionFilter = TransactionFilter.ALL,
  listingId,
}: {
  filter?: TransactionFilter;
  listingId?: string;
} = {}): UseInfiniteQueryResult<{
  meta: any;
  data: Transaction[];
}> => {
  const { currentUser } = useCurrentUser();
  const sdk = useSdk();

  return useInfiniteQuery({
    enabled: Boolean(currentUser.id?.uuid),
    queryKey: ['transactions', { transactionFilter, listingId }],
    getNextPageParam: lastPage => {
      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
      const { totalPages, page } = lastPage.meta;
      if (page < totalPages) {
        return page + 1;
      }
      return undefined;
    },
    queryFn: async ({ pageParam = 1 }) => {
      const apiQueryParams = {
        listingId,
        only:
          transactionFilter === TransactionFilter.ORDERS
            ? 'order'
            : transactionFilter === TransactionFilter.SALES
            ? 'sale'
            : undefined,
        lastTransitions: getAllTransitionsForEveryProcess(),
        include: [
          'listing',
          'provider',
          'provider.profileImage',
          'customer',
          'customer.profileImage',
          'listing.images',
        ],
        'fields.transaction': [
          'processName',
          'lastTransition',
          'lastTransitionedAt',
          'transitions',
          'payinTotal',
          'payoutTotal',
          'lineItems',
          'protectedData',
        ],
        'fields.listing': [
          'title',
          'availabilityPlan',
          'publicData.listingType',
          'publicData.brandName',
          'publicData.brandId',
        ],
        'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
        page: pageParam,
        perPage: 100,
      };

      const res = await sdk.transactions.query(apiQueryParams);
      const transactions = denormalisedResponseEntities(res);

      const listingIds = transactions.map(t => t.listing.id.uuid);

      const { listings } = await fetchListingsByIds(listingIds, {
        page: pageParam,
        perPage: 100,
      });
      const listingMap = new Map(listings.map(l => [l.id, l]));

      const data = transactions.map(transaction => {
        const userRole =
          transaction.customer.id.uuid === currentUser.id.uuid
            ? TransactionUserRole.CUSTOMER
            : TransactionUserRole.PROVIDER;
        const otherUser =
          transaction?.[
            userRole === TransactionUserRole.CUSTOMER
              ? TransactionUserRole.PROVIDER
              : TransactionUserRole.CUSTOMER
          ];

        const listing = listingMap.get(transaction.listing.id.uuid);

        let mappedListing = listing;
        if (!listing && transaction.listing) {
          mappedListing = mapSharetribeListingToListing(transaction.listing);
        }

        return {
          ...transaction,
          userRole,
          otherUser,
          isCurrentUserProvider: userRole === TransactionUserRole.PROVIDER,
          listing: mappedListing || {},
        };
      });

      return {
        meta: res.data.meta,
        data,
      };
    },
  });
};

export const TransactionUserRole = {
  CUSTOMER: 'customer',
  PROVIDER: 'provider',
};
