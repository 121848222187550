import { useIntl } from 'react-intl';

import { formatMoney, newMoney } from '../../util/currency';
import { ensureUser } from '../../util/data';
import AspectRatioWrapper from '../AspectRatioWrapper/AspectRatioWrapper';
import css from './OrderSummary.module.css';
import ResponsiveImageV2 from 'components/ResponsiveImage/ResponsiveImageV2';
import { Listing } from 'models/ListingModels';

const OrderSummary = props => {
  const currentListing: Listing = props.currentListing;
  const intl = useIntl();

  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const { aspectWidth = 3, aspectHeight = 4 } = {};

  const listingTitle = currentListing.title;
  const currentAuthor = ensureUser(currentListing.author);

  const priceFormatted = formatMoney(intl, newMoney(currentListing.price, currentListing.currency));

  const priceWhenBought = currentListing.priceWhenBought;
  const priceWhenBoughtFormatted = priceWhenBought
    ? formatMoney(
        intl,
        newMoney(priceWhenBought, currentListing.priceWhenBoughtCurrency || currentListing.currency)
      )
    : null;

  const soldBy = intl.formatMessage(
    { id: 'OrderSummary.soldBy' },
    { value: currentAuthor.attributes.profile.displayName }
  );

  return (
    <div className={css.detailsContainerDesktop}>
      <AspectRatioWrapper
        width={aspectWidth}
        height={aspectHeight}
        className={css.detailsAspectWrapper}
      >
        <ResponsiveImageV2 rootClassName={css.rootForImage} alt={listingTitle} image={firstImage} />
      </AspectRatioWrapper>

      <div className={css.listingContainer}>
        <p className={css.listingTitle}>{listingTitle}</p>

        <div style={{ display: 'inline-flex', gap: 8 }}>
          <p className={css.listingPrice}>{priceFormatted}</p>
          <p className={css.listingPriceWhenBought}>{priceWhenBoughtFormatted}</p>
        </div>

        <p className={css.listingSoldBy}>{soldBy}</p>
      </div>
    </div>
  );
};

export default OrderSummary;
