import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { denormalisedResponseEntities } from '../../../util/data';
import { useSdk } from '../../../util/sdkContext';
import { useCurrentUser } from '../../selectors/useCurrentUser';
import { getListingQueryFn } from '../listings/useGetListing';
import { Transaction, TransactionUserRole } from './useGetTransactions';
import { mapSharetribeListingToListing } from 'models/mappers/ListingMapper';

export const useGetTransaction = (transactionId: string): UseQueryResult<Transaction> => {
  const sdk = useSdk();
  const { currentUser } = useCurrentUser();

  return useQuery({
    enabled: Boolean(transactionId && currentUser.id?.uuid),
    queryKey: createQueryKey(transactionId),
    queryFn: createQueryFn(sdk, transactionId, currentUser),
    meta: {
      errorMessage: 'Failed to fetch transaction',
    },
  });
};

export const createQueryKey = (transactionId: string) => ['transactions', { transactionId }];

export const createQueryFn =
  (sdk, transactionId: string, currentUser) => async (): Promise<Transaction> => {
    const res = await sdk.transactions.show({
      id: transactionId,
      include: [
        'listing',
        'provider',
        'provider.profileImage',
        'customer',
        'customer.profileImage',
        'listing.images',
      ],
      'fields.transaction': [
        'processName',
        'lastTransition',
        'lastTransitionedAt',
        'transitions',
        'payinTotal',
        'payoutTotal',
        'lineItems',
        'protectedData',
      ],
      'fields.listing': ['title', 'availabilityPlan', 'publicData.listingType'],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    });

    const transaction = denormalisedResponseEntities(res)?.[0];

    const userRole =
      transaction?.customer?.id?.uuid === currentUser?.id?.uuid
        ? TransactionUserRole.CUSTOMER
        : TransactionUserRole.PROVIDER;
    const otherUser =
      transaction?.[
        userRole === TransactionUserRole.CUSTOMER
          ? TransactionUserRole.PROVIDER
          : TransactionUserRole.CUSTOMER
      ];

    let listing;
    try {
      listing = await getListingQueryFn(transaction.listing.id.uuid, {});
    } catch (error) {
      console.error(error);
    }

    if (!listing && transaction.listing) {
      listing = mapSharetribeListingToListing(transaction.listing);
    }

    return {
      ...transaction,
      userRole,
      otherUser,
      listing: listing || {},
      isCurrentUserProvider: userRole === TransactionUserRole.PROVIDER,
    };
  };
