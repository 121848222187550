import { useIntl } from 'react-intl';

import { NamedLink } from 'components';
import { Listing } from 'models/ListingModels';
import { cn } from 'util/cn';
import { createListingSlug } from 'util/createListingSlug';
import { formatMoney, newMoney } from 'util/currency';

export function PostingListingPreview({
  listing,
  disableLink,
}: {
  listing: Listing;
  disableLink?: boolean;
}) {
  const intl = useIntl();
  return (
    <NamedLink
      name="ListingPage"
      params={{
        id: listing.id,
        slug: createListingSlug(listing),
      }}
      className={cn('grid grid-cols-[auto_1fr] gap-1', disableLink && 'pointer-events-none')}
    >
      <img
        srcSet={listing.images[0]?.srcset}
        onError={listing.images[0]?.onError}
        className="h-[128px] aspect-[3/4] object-contain"
        alt={listing.title}
      />
      <div className="grid content-center gap-1">
        <div className="text-000 text-type-subtle uppercase font-bold font-syne">
          {listing.brand?.name}
        </div>
        <div className="font-montserrat text-0 font-normal">{listing.title}</div>
        <div className="text-0 font-semibold">
          {formatMoney(intl, newMoney(listing.price, listing.currency))}
        </div>
      </div>
    </NamedLink>
  );
}
